import { graphql, type SliceComponentProps } from 'gatsby';
import React, { type FC } from 'react';

import { Html } from '@/components/content/html';

interface AdvantagesAndDisadvantagesProps {
    firm: GraphqlSelect.Firm<'name' | 'advantages' | 'disadvantages' | 'descriptionAdvantages'>;
}

const AdvantagesAndDisadvantages: FC<SliceComponentProps<AdvantagesAndDisadvantagesProps>> = ({ data: { firm } }) => {
    if (!firm.advantages || !firm.disadvantages) {
        return null;
    }

    return (
        <div style={{ margin: 'clamp(16px, 1.7vw, 22px) 0' }}>
            <Html>
                {`<h2 class="fs-36">Avantajele si dezavantajele ${firm.name}</h2>` +
                    (firm.descriptionAdvantages?.html || '')}
            </Html>

            <div style={{ marginTop: 'clamp(16px, 1.7vw, 22px)' }} className="d-flex flex-wrap g-16">
                <div style={{ flex: '1 1 340px', border: '1px solid var(--green)' }} className="box bg-green-2">
                    <strong className="fs-18">Ventajas</strong>

                    <ol
                        className="list list-padding list-with-plus mt-16"
                        dangerouslySetInnerHTML={{ __html: firm.advantages }}
                    />
                </div>

                <div style={{ flex: '1 1 340px', border: '1px solid #e34747' }} className="box bg-red">
                    <strong className="fs-18">Desventajas</strong>

                    <ol
                        className="list list-padding list-with-times mt-16"
                        dangerouslySetInnerHTML={{ __html: firm.disadvantages }}
                    />
                </div>
            </div>
        </div>
    );
};

export const query = graphql`
    query AdvantagesAndDisadvantages($firmId: String) {
        firm(id: { eq: $firmId }) {
            name
            advantages
            disadvantages

            descriptionAdvantages {
                html
            }
        }
    }
`;

export default AdvantagesAndDisadvantages;
